<template>
  <div class="certifications">
    <p class="introduction">
      Feel free to check out my profile on <a :href=social.acclaim target="_blank">Credly</a> for a list of all exams and certifications.<br>
      Most of my certifications and exams are within products and services from <strong>Microsoft</strong> and <strong>Zendesk</strong>.
    </p>
    <sui-card-group :items-per-row="2">
      <sui-card v-for="certification in certifications" v-bind:key="certification.name"
      class="link" @click='window.open(certification.certification_url, "_blank")'>
        <sui-card-content>
          <sui-card-header>{{certification.name}}</sui-card-header>
          <sui-card-meta>
            <span class="category">{{certification.category}}
            </span>
          </sui-card-meta>
          <sui-card-description>
            {{certification.description}}
          </sui-card-description>
        </sui-card-content>
        <sui-card-content extra>
          <span class="certification-achieved">Achieved: {{certification.achieved}}</span>
          <span slot="right">
            {{certifications.thumbnail}}
            <sui-image :src='getCertificationThumbnailSrc(certification.thumbnail)' shape="circular" size="mini" />
          </span>
        </sui-card-content>
      </sui-card>
    </sui-card-group>
  </div>
</template>
<script>
import Certifications from '@/config/certifications.json'
import Social from '@/config/social.json'

export default {
  name: 'Certifications',
  data: () => {
    return {
      certifications: Certifications,
      social: Social,
      window: window
    }
  },
  methods: {
    getCertificationThumbnailSrc (src) {
      return require('../assets/certifications/img/' + src)
    }
  }
}
</script>
<style>
.certifications {
  width: 600px;
  margin: 0 auto;
  max-width: 90%;
}

@media (max-width:480px) {
  .certifications .ui.two.cards {
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  .certifications .ui.card, .ui.cards>.card {
    max-width: 90%;
  }

  .certifications .ui.two.cards>.card {
    width: 90%;
    margin-left: 1em;
    margin-right: 1em;
  }

  .certification-achieved {
    display: inline-block;
  }
}
</style>
